(function(APP, $, window) {
  'use strict';

  /**
   * Menu class
   * @param {Object} options
   */
  function Menu(options) {
    if (!(this instanceof Menu)) return new Menu(options);

    this.isOpen = false;
    this.$btnToggle = $('.js-header-btn');

    this.$btnToggle.on('click', function() {
      if (this.isOpen) {
        this.isOpen = false;
        APP.$html.addClass('is-main-header-close').removeClass('is-main-header-open');
      } else {
        this.isOpen = true;
        APP.$html.addClass('is-main-header-open').removeClass('is-main-header-close');
      }
    }.bind(this));

    return this;
  }

  $(window).on('load', function() {
    APP.store.Menu = new Menu();
  });
})(APP, jQuery, window);
